import { useNavigate } from "react-router-dom";
import translator from "../../../Components/Extra/Translation/Translate";
import BreadCrumbV2, {
    Ariane,
} from "../../../Layouts/Account/BreadCrumb/BreadCrumbV2";
import NoticeDragDrop from "./DragNdrop/TemplateEmailDragDrop";

const Arianes: Ariane[] = [
    {
        path: "#",
        name: "Admin",
    },
    {
        path: "/admin/template-email",
        name: "Template Email",
    },
    {
        path: "/admin/template-email",
        name: "Liste",
    },
];

const TemplateEmailList = () => {
    const { translate } = translator();
    const navigate = useNavigate();
    return (
        <div>
            <BreadCrumbV2 arianes={Arianes} />
            <h2 className="intro-y mt-5 font-medium text-lg  bg-white p-2 rounded">
                {translate("Admin_Notice", "List_A_Template_Email")}
            </h2>
            <button
                className="btn btn-perfect my-5 mr-2"
                onClick={() => navigate("/admin/template-email/new")}
            >
                {translate("Admin_Assets", "Add_New")}
            </button>
            <NoticeDragDrop />
        </div>
    );
};

export default TemplateEmailList;
