import { Outlet } from "react-router-dom";
import Header from "./PaymentHeader";

const PaymentBase = () => {
    return (
        <div>
            {/* <Header /> */}
            <div>
                <Outlet></Outlet>
            </div>
        </div>
    );
};

export default PaymentBase;
