import AnonymousRouteProvider from "../../Routes/RouteProvider/AnonymousRouteProvider";
import RoutesNames from "../../Services/RoutesNames";
import KalyssiBase from "../layout/Kalyssi/KalyssiBase";
import PaymentBase from "../pages/Payment/PaymentBase";
import SupportLayout from "../pages/(support-pages)/layout";
import Login from "../pages/Login/Login";
import Maintenance from "../pages/Maintenace/Maintenance";
import ForgotPassword from "../pages/Password/ForgotPassword/ForgotPassword";
import NewPassword from "../pages/Password/NewPassword/NewPassword";
import KalyssiPayment from "../pages/Payment/KalyssiPayment";
import PaymentError from "../pages/Payment/PaymentError";
import PaymentSuccess from "../pages/Payment/PaymentSuccess";
import Register from "../pages/Register/Register";
import About from "../pages/about-page";
import Branding from "../pages/branding/branding";
import KycAml from "../pages/informations/KycAml";
import LegalWarning from "../pages/informations/LegalWarning";
import OurFee from "../pages/informations/OurFee";
import Invest from "../pages/invest/Invest";
import Error from "../pages/Error/Error";
import Home from "../pages/Home/index";
import Feature from "../pages/Feature/Index";
import Roadmap from "../pages/Learn/Roadmap";
import Contact from "../pages/Contact";
import Confidentiality from "../pages/Mention/confidentiality";
import Condition from "../pages/Mention/conditions";
import MentionLegale from "../pages/Mention/mentions";
import DocBase from "../../Doc/Layout/DocBase";
import Introduction from "../../Doc/pages/Introduction";
import Integration from "../../Doc/pages/Integration";
import ComingSoon from "../pages/Coming/index";
import VerifyPhone from "../pages/verify-phone/verify-phone";

const LandingRoutes = [
    {
        element: <KalyssiBase />,
        children: [
            {
                path: RoutesNames.Home,
                element: <Home />,
            },
            {
                path: RoutesNames.Invest,
                element: <Invest />,
            },
            {
                path: RoutesNames.About,
                element: <About />,
            },
            {
                path: RoutesNames.Feature,
                element: <Feature />,
            },
            {
                path: RoutesNames.Branding,
                element: <Branding />,
            },
            {
                path: RoutesNames.MentionLegale,
                element: <MentionLegale />,
            },
            {
                path: RoutesNames.Roadmap,
                element: <Roadmap />,
            },
            {
                path: RoutesNames.Contact,
                element: <Contact />,
            },
            {
                path: RoutesNames.Condition,
                element: <Condition />,
            },
            {
                path: RoutesNames.Confidentiality,
                element: <Confidentiality />,
            },
            {
                path: RoutesNames.LegalWarning,
                element: <LegalWarning />,
            },
            {
                path: RoutesNames.OurFee,
                element: <OurFee />,
            },
            {
                path: RoutesNames.KycAml,
                element: <KycAml />,
            },
            {
                path: RoutesNames.ComingSoon,
                element: <ComingSoon />,
            },
        ],
    },
    {
        path: "support/:group/:item",
        element: <SupportLayout />,
    },

    {
        element: <PaymentBase />,
        children: [
            {
                path: RoutesNames.PaymentKp,
                element: <KalyssiPayment />,
            },
        ],
    },
    {
        path: "/payment/error",
        element: <PaymentError />,
    },
    {
        path: "/payment/cancel",
        element: <PaymentError />,
    },
    {
        path: "/payment/success",
        element: <PaymentSuccess />,
    },
    {
        path: "documentation",
        element: <DocBase />,
        children: [
            {
                path: "introduction",
                element: <Introduction />,
            },
            {
                path: "payment",
                element: <Integration />,
            },
        ],
    },
    {
        element: <AnonymousRouteProvider />,
        children: [
            {
                path: RoutesNames.Login,
                element: <Login />,
            },
            {
                path: RoutesNames.SilentLogin,
                element: <Login />,
            },
            {
                path: `${RoutesNames.Register}/:parrain?`,
                element: <Register />,
            },
            {
                path: `${RoutesNames.SilentRegister}/:parrain?`,
                element: <Register />,
            },
            {
                path: "forgot-password",
                element: <ForgotPassword />,
            },
            {
                path: "reset-password",
                element: <NewPassword />,
            },
            {
                path: "unlock-account/:uid/:code",
                element: <NewPassword />,
            },
        ],
    },
    {
        path: "*",
        element: <Error />,
    },
    {
        path: "maintenance",
        element: <Maintenance />,
    },
    {
        path: RoutesNames.VerifyPhone,
        element: <VerifyPhone />,
    },
];

export default LandingRoutes;
