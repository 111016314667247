import { NavLink } from "react-router-dom";

export default function SupportHeader() {
    return (
        <header className="fixed w-full bg-white border-b border-slate-200 z-30 mt-[80px]">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="flex items-center justify-between h-16 md:h-20">
                    {/* Site branding */}
                    <div className="shrink-0 mr-4">
                        {/* Logo */}
                        <NavLink
                            className="flex items-center"
                            to="/"
                            aria-label="Cruip"
                        >
                            <svg
                                width="30"
                                height="30"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g fillRule="nonzero" fill="none">
                                    <g
                                        className="fill-blue-500"
                                        transform="translate(3 3)"
                                    >
                                        <circle cx="5" cy="5" r="5" />
                                        <circle cx="19" cy="5" r="5" />
                                        <circle cx="5" cy="19" r="5" />
                                        <circle cx="19" cy="19" r="5" />
                                    </g>
                                    <g className="fill-sky-300">
                                        <circle cx="15" cy="5" r="5" />
                                        <circle cx="25" cy="15" r="5" />
                                        <circle cx="15" cy="25" r="5" />
                                        <circle cx="5" cy="15" r="5" />
                                    </g>
                                </g>
                            </svg>
                            <span className="text-slate-400 font-[550] ml-4">
                                Support Center
                            </span>
                        </NavLink>
                    </div>

                    {/* Desktop navigation */}
                    <nav className="flex grow">
                        {/* Desktop sign in links */}
                        <ul className="flex grow justify-end flex-wrap items-center">
                            <li className="ml-3">
                                <a
                                    className="btn-sm inline-flex items-center text-slate-100 bg-slate-800 hover:bg-slate-900 shadow-sm"
                                    href="#0"
                                >
                                    Get in touch
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    );
}
