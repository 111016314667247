import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import type { Identifier, XYCoord } from "dnd-core";
import { ItemTypes } from "../../../Layout/DragDrop/ItemTypes";
import { NavLink } from "react-router-dom";
import alert_message from "../../../../Services/Alert/AlertMessage";
import translator from "../../../../Components/Extra/Translation/Translate";
import Swal from "sweetalert2";

import { LangType } from "../../../../Models/Lang";
import TemplateMail from "../../../../Models/TemplateEmail";
import TemplateEmailService from "../../../../Services/TemplateEmail/TemplateEmailService";
import { useModalContext } from "../../../../Components/Extra/Modal/ModalContext";
import SendMailForm from "../SendMailForm";

export interface CardProps {
    id: any;
    index: number;
    template: TemplateMail;
    lang: LangType;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    refreshList: () => void;
}

interface DragItem {
    index: number;
    id: string;
    type: string;
}

const Card: React.FC<CardProps> = ({
    id,
    index,
    template,
    moveCard,
    refreshList,
    lang,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const { handleModal } = useModalContext();
    const { translate } = translator();

    const [{ handlerId }, drop] = useDrop<
        DragItem,
        void,
        { handlerId: Identifier | null }
    >({
        accept: ItemTypes.CARD,
        collect: (monitor) => ({
            handlerId: monitor.getHandlerId(),
        }),
        hover(item: DragItem, monitor) {
            if (!ref.current) return;
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) return;

            const hoverBoundingRect = ref.current.getBoundingClientRect();
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY =
                (clientOffset as XYCoord).y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: { id, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    const deleteById = async (
        event: React.MouseEvent<HTMLButtonElement>,
        elt: TemplateMail,
    ) => {
        event.preventDefault();
        Swal.fire({
            title: "Suppression d'un email",
            text: `${translate(
                "Admin_Affiliation",
                "Do_You_Want_To_Delete_The",
            )} ${elt.templateName} ?`,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#daa520",
            cancelButtonText: translate("Admin_Affiliation", "Cancel"),
        }).then(async (res) => {
            if (res.isConfirmed && elt.id !== undefined) {
                try {
                    await TemplateEmailService.remove(elt.id);
                    alert_message.success("Suppression réussie");
                    refreshList();
                } catch (error) {
                    alert_message.error("Erreur lors de la suppression");
                    console.error("Error deleting template:", error);
                }
            }
        });
    };

    return (
        <div
            ref={ref}
            style={{ opacity: isDragging ? 0.4 : 1 }}
            data-handler-id={handlerId}
            className="box shadow rounded px-4 py-3 mb-2 cursor-move"
        >
            <div className="flex w-full">
                <div className="mr-5 w-[50%]">{template.templateName}</div>
                <div className="flex items-center justify-center w-[50%]">
                    <NavLink
                        className="mr-3 flex items-center"
                        to={`edit/${template.id}`}
                        state={{ asset: template }}
                    >
                        <i
                            data-lucide="check-square"
                            className="mr-1 h-4 w-4"
                        ></i>
                        {translate("Admin_Affiliation", "Edit")}
                    </NavLink>
                    <button
                        className="text-danger flex items-center"
                        type="button"
                        onClick={(event) => deleteById(event, template)}
                    >
                        <i data-lucide="trash-2" className="mr-1 h-4 w-4"></i>{" "}
                        {translate("Admin_Affiliation", "Delete")}
                    </button>
                    <button
                        className="text-kalypay-600 flex items-center"
                        type="button"
                        onClick={(event) =>
                            handleModal(<SendMailForm id={template.id!} />)
                        }
                    >
                        <i data-lucide="trash-2" className="mr-1 h-4 w-4"></i>{" "}
                        Envoyer
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Card;
