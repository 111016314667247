import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import translator from "../../Components/Extra/Translation/Translate";
import {
    KpAssetIcon,
    MosaicDashboard,
    KpTransactionIcon,
    KpCreditCardIcon,
    KpColdWalletIcon,
    KpEcosystemIcon,
    KpPaymentIcon,
    KpIbanIcon,
    KpMarkerIcon,
    KpLoyaltyIcon,
    KpEarnIcon,
    KpSettingIcon,
    KpHelpIcon,
    KpLoanIcon,
    KpPartenarIcon,
    KpOrderIcon,
    KpUsersIcon,
    KpModerationIcon,
    KpVirementIcon,
    KpResComIcon,
    KpComIcon,
    KpPosIcon,
    KpServicesIcon,
    KpAMLIcon,
    KpTelegramIcon,
    KpMailIcon,
} from "../Icons/MosaicIcons";
import RoutesNames from "../../Services/RoutesNames";
import { useAppProvider } from "../Provider/AppProvider";
import { useFaqContext } from "../../Components/FAB/FaqContext";

export type ItemListProps = {
    name: string;
    icon: any;
    path?: string;
    redirectPath?: string;
    childs: ItemListProps[];
    allowed?: boolean;
    disabled?: boolean;
    hideIcon?: boolean;
    notif?: number;
    onClick?: () => void;
};

export type GroupedMenu = {
    label: string;
    menuList: ItemListProps[];
};

const ItemListComponent = ({
    name,
    icon,
    path,
    redirectPath,
    disabled = false,
    notif,
    onClick,
}: ItemListProps) => {
    const location = useLocation();
    const { translate } = translator();

    const { setSidebarOpen } = useAppProvider();

    if (!path) {
        return (
            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 `}>
                <button
                    onClick={() => {
                        if (onClick) onClick();
                        setSidebarOpen(false);
                    }}
                    className={`block  truncate transition duration-150  text-slate-400 hover:text-kalypay-700`}
                >
                    <div className="flex items-center">
                        {icon}
                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ">
                            {translate(".", name)}
                        </span>
                    </div>
                </button>
            </li>
        );
    }

    if (path.includes("http")) {
        return (
            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 `}>
                <a
                    href={path}
                    target="_blank"
                    className={`block  truncate transition duration-150  text-slate-400 hover:text-kalypay-700`}
                >
                    <div className="flex items-center">
                        {icon}
                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ">
                            {translate(".", name)}
                        </span>
                    </div>
                </a>
            </li>
        );
    }

    return (
        <li
            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                location.pathname.includes(path) &&
                "bg-slate-100 dark:bg-slate-900"
            }`}
        >
            {disabled ? (
                <NavLink
                    to={"#"}
                    className={`block  truncate transition duration-150 cursor-not-allowed  hover:text-kalypay-700 text-slate-400 ${
                        location.pathname.includes(path)
                            ? "hover:text-kalypay-700 dark:text-slate-200  dark:hover:text-kalypay-700"
                            : "hover:text-kalypay-700"
                    }`}
                >
                    <div className="flex items-center">
                        {icon}
                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ">
                            {translate(".", name)}
                        </span>
                    </div>
                </NavLink>
            ) : (
                <NavLink
                    to={redirectPath ? redirectPath : path}
                    target={path.includes("http") ? "_blank" : "_self"}
                    onClick={() => {
                        if (onClick) onClick();
                        setSidebarOpen(false);
                    }}
                    className={`block  truncate transition duration-150 hover:text-kalypay-700 text-slate-400 ${
                        location.pathname.includes(path)
                            ? "hover:text-kalypay-700 dark:text-slate-200  dark:hover:text-kalypay-700"
                            : "hover:text-kalypay-700"
                    }`}
                >
                    <div className="flex items-center">
                        {icon}
                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ">
                            {translate(".", name)}
                        </span>
                        {notif !== undefined && notif > 0 && (
                            <div className="flex flex-shrink-0 ml-2">
                                <span className="inline-flex items-center justify-center h-5 text-xs font-medium text-white bg-kalypay-500 px-2 rounded">
                                    {notif}
                                </span>
                            </div>
                        )}
                    </div>
                </NavLink>
            )}
        </li>
    );
};

const GroupeMenuComponent = ({ groups }: { groups: GroupedMenu[] }) => {
    return (
        <>
            {groups.map((group, index) => (
                <div key={`menu-group-${index}`}>
                    <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
                        <span
                            className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden xl:hidden text-center w-6"
                            aria-hidden="true"
                        >
                            •••
                        </span>
                        <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            {group.label}
                        </span>
                    </h3>
                    <ul className="mt-3">
                        {group.menuList.map((menu, i) =>
                            menu.allowed === undefined || menu.allowed ? (
                                <ItemListComponent
                                    key={i}
                                    name={menu.name}
                                    icon={menu.icon}
                                    path={menu.path}
                                    redirectPath={menu.redirectPath}
                                    childs={menu.childs}
                                    disabled={menu.disabled}
                                    notif={menu.notif}
                                    onClick={menu.onClick}
                                />
                            ) : null,
                        )}
                    </ul>
                </div>
            ))}
        </>
    );
};

const SideMenu = () => {
    const location = useLocation();
    const { translate } = translator();
    const { setChatType } = useFaqContext();

    const AccountMenu: GroupedMenu[] = [
        {
            label: translate("ACCOUNT_MENU", "FINANCES"),
            menuList: [
                {
                    name: "ACCOUNT.ACCOUNT",
                    path: RoutesNames.Assets,
                    icon: (
                        <KpAssetIcon
                            active={location.pathname.includes("assets")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.CARDS",
                    path: RoutesNames.Cards,
                    icon: (
                        <KpCreditCardIcon
                            active={location.pathname.includes("cards")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.TRANSACTIONS",
                    path: RoutesNames.Transactions,
                    icon: (
                        <KpTransactionIcon
                            active={location.pathname.includes("transactions")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.PAYMENT",
                    path: RoutesNames.Payment,
                    icon: (
                        <KpPaymentIcon
                            active={location.pathname.includes("payment")}
                        />
                    ),
                    childs: [],
                    // disabled: true,
                },
            ],
        },
        {
            label: translate("ACCOUNT_MENU", "SUPPORT"),
            menuList: [
                {
                    name: "ACCOUNT_MENU.ECOSYSTEM",
                    path: RoutesNames.Ecosystem.Index,
                    redirectPath: RoutesNames.Ecosystem.Index,
                    icon: (
                        <KpEcosystemIcon
                            active={location.pathname.includes("ecosystem")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.LOYALTY",
                    path: RoutesNames.Loaylty,
                    icon: (
                        <KpLoyaltyIcon
                            active={location.pathname.includes("loyalty")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "Profil.Affiliation",
                    path: RoutesNames.Referrals,
                    redirectPath: RoutesNames.Referrals,
                    icon: (
                        <KpSettingIcon
                            active={location.pathname.includes("referals")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.SECURTIY_AND_SETTING",
                    path: RoutesNames.Setting.Index,
                    redirectPath: RoutesNames.Setting.General,
                    icon: (
                        <KpUsersIcon
                            active={location.pathname.includes("settings")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "FAQ",
                    path: RoutesNames.Faq,
                    icon: (
                        <KpHelpIcon
                            active={location.pathname.includes("faq")}
                        />
                    ),
                    childs: [],
                    allowed: true,
                },
                {
                    name: "Telegram",
                    icon: (
                        <KpTelegramIcon
                            active={location.pathname.includes("faq")}
                        />
                    ),
                    childs: [],
                    allowed: true,
                    // onClick: () => setChatType("telegram")
                    path: "https://t.me/kalypay",
                },
                {
                    name: "Mail",
                    icon: <KpMailIcon />,
                    childs: [],
                    allowed: true,
                    onClick: () => setChatType("email"),
                },
            ],
        },
    ];

    return <GroupeMenuComponent groups={AccountMenu} />;
};

export default SideMenu;
