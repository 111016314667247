import { useRef, useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";

interface WysiwigInputProps {
    content: string;
    setContent: (content: string) => void;
}

const WysiwigInput = ({ content, setContent }: WysiwigInputProps) => {
    const editorRef = useRef<any>(null);
    const [defaultContent, setDefaultContent] = useState(content);

    const toolbarOptions = [
        "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | forecolor backcolor | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
    ];

    const plugins = [
        "advlist",
        "lists",
        "link",
        "image",
        "charmap",
        // "print",
        "preview",
        "anchor",
        "searchreplace",
        "insertdatetime",
        "media",
        "table",
        // "paste",
        "help",
        "code",
    ];
    const handleChange = () => {
        if (editorRef.current) {
            setContent((editorRef.current as any).getContent());
        }
    };
    return (
        <>
            {editorRef && (
                <Editor
                    apiKey="8hi2372tucpfyzoqd3082ftjv73wauesuk4fw20dkbxb3ez0"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={defaultContent}
                    onEditorChange={(evt, editor) => handleChange()}
                    init={{
                        height: 500,
                        menubar: false,
                        plugins: plugins,
                        toolbar: toolbarOptions,
                        content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                />
            )}
        </>
    );
};

export default WysiwigInput;
