import { Lock } from "lucide-react";
import translator from "../../../Components/Extra/Translation/Translate";

export default function AccountBlocked() {
    const { translate } = translator();

    return (
        <div className="flex items-center justify-center  p-4">
            <div className=" rounded-2xl p-6 max-w-sm text-center">
                <div className="flex justify-center mb-4">
                    <div className="bg-red-500 text-white p-3 rounded-full">
                        <Lock size={32} />
                    </div>
                </div>
                <h2 className="text-xl font-semibold text-gray-900">
                    {translate("Account_Blocked", "Account_Blocked")}
                </h2>
                <p className="text-gray-600 mt-2 text-sm">
                    {translate("Account_Blocked", "Indication")}
                </p>
            </div>
        </div>
    );
}
