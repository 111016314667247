import { IUserSignUp } from "../../Models/User";
import axios from "../AxiosInstance";

const verifyToken = async () => {
    try {
        const result = await axios.post(`/verify-token`);
        return {
            error: false,
            response: result,
        };
    } catch (err: any) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const signIn = async (
    username: string,
    password: string,
    recaptcha_token: string | null,
    rememberMe: boolean,
) => {
    try {
        const result = await axios.post(`/auth/sign-in`, {
            login: username,
            password,
            token: recaptcha_token,
            rememberMe: rememberMe,
        });

        return {
            error: false,
            response: result,
        };
    } catch (err: any) {
        return {
            error: true,
            response: err.response,
            message: err.message,
        };
    }
};

const verify2FA = async (token: string) => {
    try {
        const result = await axios.post(`/auth/verify-2fa`, {
            token,
        });

        return {
            error: false,
            response: result,
        };
    } catch (err: any) {
        return {
            error: true,
            response: err.response,
            message: err.message,
        };
    }
};

const logout = async () => {
    try {
        return await axios.get(`/auth/logout`);
    } catch (err: any) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const getInformation = async () => {
    try {
        const result = await axios.get(`/user/information`);
        return {
            error: false,
            response: result,
        };
    } catch (err: any) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const signUp = async (payload: IUserSignUp, repeatPassword: string) => {
    try {
        if (payload.password !== repeatPassword) {
            return Promise.reject(
                "Password and repeat password does not match",
            );
        }
        const result = await axios.post(`/auth/signup`, {
            ...payload,
        });
        return result.data;
    } catch (err: any) {
        const error =
            err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;
        return Promise.reject(error);
    }
};

export { verifyToken, signIn, logout, getInformation, signUp, verify2FA };
