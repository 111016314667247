import {
    ICommissionType,
    IResetTransactionPassword,
    IUserPasswordUpdate,
    IUserTransactionPasswordCreate,
    MailPayload,
    Token2Fa,
    User2Fa,
    WithdrawSecurityType,
    WithdrawSecurityTypePayload,
} from "../../../Models/User";
import axios from "../../AxiosInstance";
import UtilsService from "../../UtilsService";

const updateUserPhoto = async (formData: FormData) => {
    try {
        const result = await axios.put(`/user/update-profile-photo`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        return Promise.resolve(result.data.message);
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(error);
    }
};

const updateUserPassword = async (
    payload: IUserPasswordUpdate,
    confirmNewPassword: string,
    type: "transaction" | "password",
    translate: any,
) => {
    try {
        if (payload.newPassword !== confirmNewPassword) {
            return Promise.reject(
                type === "password"
                    ? translate(
                          "Security_Password",
                          "New_Password_New_Confirm_Password_Not_Match",
                      )
                    : translate(
                          "Security_Transaction",
                          "New_Password_New_Confirm_Password_Not_Match",
                      ),
            );
        }
        const endpoint =
            type === "transaction"
                ? "/user/update-transaction-password"
                : "/user/update-password";

        const result = await axios.put(`${endpoint}`, {
            ...payload,
        });
        return Promise.resolve(
            type === "password"
                ? translate("Security_Password", result.data.code)
                : translate("Security_Transaction", result.data.code),
        );
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;
        if (err.message) return Promise.reject(error);
        return Promise.reject(
            type === "password"
                ? translate("Security_Password", err.response.data.code)
                : translate("Security_Transaction", err.response.data.code),
        );
    }
};

const creatUserTransactionPassword = async (
    payload: IUserTransactionPasswordCreate,
    confirmTransactionPassword: string,
) => {
    try {
        if (payload.transactionPassword !== confirmTransactionPassword) {
            return Promise.reject(
                "Transaction password and confirm Transaction password does not match",
            );
        }
        const result = await axios.post(`/user/create-transaction-password`, {
            ...payload,
        });

        return Promise.resolve(result.data.message);
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(error);
    }
};

const get2FaStatus = async (): Promise<User2Fa> => {
    try {
        const result = await axios.get(`/user/get-2fa-status`);

        return result.data as User2Fa;
    } catch (err: any) {
        return Promise.reject(UtilsService.getAxiosErrorMessage(err));
    }
};

const enable2FA = async (token: Token2Fa): Promise<User2Fa> => {
    try {
        const result = await axios.post(`/user/enable-2fa`, { ...token });

        return result.data as User2Fa;
    } catch (err: any) {
        return Promise.reject(UtilsService.getAxiosErrorMessage(err));
    }
};

const disabled2FA = async (token: Token2Fa): Promise<User2Fa> => {
    try {
        const result = await axios.post(`/user/disable-2fa`, { ...token });

        return result.data as User2Fa;
    } catch (err: any) {
        return Promise.reject(UtilsService.getAxiosErrorMessage(err));
    }
};

const generateOTPUrl = (config: User2Fa): string => {
    return `otpauth://totp/${config.application}:${config.username}?secret=${config.key}&issuer=${config.application}`;
};

const sendInvitationMailSetting = async (to_email: string): Promise<string> => {
    return axios
        .post("/user/invite", { to_email: to_email })
        .then(({ data }) => {
            return Promise.resolve(data.message);
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const updateUserCommissionType = async (
    payload: ICommissionType,
): Promise<string> => {
    return axios
        .put(`/user/user-commission-type`, payload)
        .then(({ data }) => {
            return Promise.resolve(data.message);
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const forgotUserTransactionPassword = async () => {
    try {
        const result = await axios.get(
            `/meta-data/forgot-transaction-password`,
        );

        return Promise.resolve(result.data.message);
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(error);
    }
};

const resetUserTransactionPassword = async (
    payload: IResetTransactionPassword,
    confirmTransactionPassword: string,
) => {
    try {
        if (payload.new_password !== confirmTransactionPassword) {
            return Promise.reject(
                "Transaction password and confirm Transaction password does not match",
            );
        }
        const result = await axios.post(
            `/meta-data/reset-transaction-password`,
            { ...payload },
        );

        return Promise.resolve(result.data.message);
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(error);
    }
};

const getWithdrawSecurityType = async (): Promise<WithdrawSecurityType> => {
    try {
        const result = await axios.get(`/user/get-withdraw-security`);

        return result.data as WithdrawSecurityType;
    } catch (err: any) {
        return Promise.reject(UtilsService.getAxiosErrorMessage(err));
    }
};

const updateWithdrawSecurityType = async (
    payload: WithdrawSecurityTypePayload,
): Promise<string> => {
    return axios
        .put(`/user/update-withdraw-security`, payload)
        .then(({ data }) => {
            return Promise.resolve(data.message);
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const updateUserMail = async (payload: MailPayload): Promise<string> => {
    return axios
        .put(`/user/update-user-mail`, payload)
        .then(({ data }) => {
            return Promise.resolve(data.message);
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const verifyPhone = async (code: string): Promise<string> => {
    return axios
        .put(`/user/verify-phone`, { code })
        .then(({ data }) => {
            return Promise.resolve(data.message);
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const resendVeridicationCode = async (): Promise<string> => {
    return axios
        .put(`/user/resend-phone-code`)
        .then(({ data }) => {
            return Promise.resolve(data.message);
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const AppProfileService = {
    updateUserPhoto,
    updateUserPassword,
    creatUserTransactionPassword,
    get2FaStatus,
    enable2FA,
    disabled2FA,
    generateOTPUrl,
    sendInvitationMailSetting,
    updateUserCommissionType,
    forgotUserTransactionPassword,
    resetUserTransactionPassword,
    getWithdrawSecurityType,
    updateWithdrawSecurityType,
    updateUserMail,
    resendVeridicationCode,
    verifyPhone,
};

export default AppProfileService;
