import { DatatableFilter } from "../../../Models/DatatableFilter";
import User from "../../../Models/User";
import axios from "../../AxiosInstance";
import UtilsService from "../../UtilsService";

const map = (item: any): User => {
    const user = new User(
        item.uid,
        item.username,
        item.last_name,
        item.first_name,
        item.email,
        item.reinvest_auto,
        item.hide_zero_amount,
        item.country,
        item.is_phone_verified,
        item.last_time_code_sent,
    );

    user.scopes = item.scopes;
    user.created_at = item.created_at;
    user.cag = item.cag;
    user.cap = item.cap;
    user.photo = item.photo;
    user.is_active = item.is_active;
    user.kyc = item.kyc || "";
    user.type = item.type;
    user.siret = item.siret;
    user.entreprise = item.entreprise;
    user.fidelity = item.fidelity;
    user.date_of_birth = item.date_of_birth;
    user.city = item.city;
    user.longitude = item.longitude;
    user.latitude = item.latitude;
    user.post_code = item.post_code;
    user.address = item.address;
    user.phone_country_code = item.phone_country_code;
    user.phone = item.phone;

    return user;
};

const getAll = async (filter: DatatableFilter): Promise<any> => {
    return axios
        .get(`/point-of-sale`, { params: filter })
        .then(({ data }) => {
            const assets: User[] = data.data.map((item: any) => map(item));

            return {
                data: assets,
                count: data.count,
                currentPage: data.currentPage,
                nextPage: data.nextPage,
                prevPage: data.prevPage,
                lastPage: data.lastPage,
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const PointOfSaleService = { getAll };

export default PointOfSaleService;
