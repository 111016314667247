import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import translator from "../../../Components/Extra/Translation/Translate";
import KpButton from "../../../Front/Components/Inputs/KpButton";
import KpInput from "../../../Front/Components/Inputs/KpInput";
import alert_message from "../../../Services/Alert/AlertMessage";
import KpPaymentService, {
    KpPayementAccess,
    KpPayementInformation,
} from "../../../Services/Payment/KpPayment";
import UtilsService from "../../../Services/UtilsService";

interface PaymentAuthType {
    account: string;
    password: string;
    code?: string;
}
const KalypayPayment = () => {
    const { translate } = translator();
    const [paymentData, setPaymentData] = useState<KpPayementInformation>();
    const [processing, setProcessing] = useState<boolean>(true);
    const [token, setToken] = useState<KpPayementAccess>();

    const location = useLocation();

    // Use the URLSearchParams API to parse and access query parameters
    const queryParams = new URLSearchParams(location.search);
    const ref = queryParams.get("ref");

    const getPaymentData = async (ref: string) => {
        setProcessing(true);
        try {
            const data = await KpPaymentService.getInformation(ref);

            setPaymentData(data);
        } catch (err: any) {
            alert_message.error(err);
        }
        setProcessing(false);
    };
    const form = useForm<PaymentAuthType>({});

    const authentication = async (data: PaymentAuthType) => {
        setProcessing(true);
        try {
            const response = await KpPaymentService.authorisation({
                account: data.account,
                ref: ref || "",
                password: data.password,
            });

            setToken(response);
        } catch (err: any) {
            alert_message.error(err);
        }
        setProcessing(false);
    };

    const accept = async () => {
        setProcessing(true);
        try {
            const response = await KpPaymentService.processPayment(
                token?.access_token || "",
                ref || "",
            );

            alert_message.info(response.message);
            setTimeout(function () {
                window.location = response.redirect;
            }, 3000);
        } catch (err: any) {
            alert_message.error(err);
            setProcessing(false);
        }
    };

    const cancel = async () => {
        setProcessing(true);
        try {
            const response = await KpPaymentService.cancelPayment(ref || "");

            alert_message.info(response.message);
            setTimeout(function () {
                window.location = response.redirect;
            }, 3000);
        } catch (err: any) {
            alert_message.error(err);
            setProcessing(false);
        }
    };

    useEffect(() => {
        getPaymentData(ref || "");
    }, [ref]);

    return (
        <div className="login h-screen !p-0">
            <div className=" mx-auto px-2 max-w-5xl">
                <div className="block grid-cols-12 gap-4 xl:grid">
                    <div className="col-span-12 z-[10] my-40 ">
                        <div className=" rounded-lg text-lg border-1 ">
                            <div
                                className={`border-2 border-black rounded-lg ${
                                    processing || !paymentData ? "blur" : ""
                                }`}
                            >
                                <div className="bg-black p-5 ">
                                    <div className="flex items-center justify-between ">
                                        <div className="flex items-center">
                                            <div>
                                                <img
                                                    src={UtilsService.getPulicImage(
                                                        "/dist/logo/Kalyssi-2.png",
                                                    )}
                                                    className="w-36"
                                                    alt="Kalypay Logo"
                                                />
                                            </div>
                                            <div className="ml-3 text-kalyssi-300">
                                                Ordre de paiement
                                            </div>
                                        </div>
                                        <div className="text-green-500 uppercase text-bold">
                                            Transfert securisé
                                        </div>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="justify-center mx-auto max-w-[500px] text-black dark:text-white py-10 ">
                                        <div className="">
                                            <div className="">
                                                <strong>
                                                    {" "}
                                                    Paiement vers :{" "}
                                                </strong>
                                                <span>
                                                    {paymentData?.account}(
                                                    {paymentData?.payee_name} )
                                                </span>
                                            </div>
                                            <div className="">
                                                <strong> Montant : </strong>
                                                <span>
                                                    {" "}
                                                    {paymentData?.amount}{" "}
                                                    {paymentData?.wallet}
                                                </span>
                                            </div>
                                            <div className="">
                                                <strong> Référence :</strong>
                                                <span>
                                                    {" "}
                                                    {paymentData?.reference}
                                                </span>
                                            </div>
                                            <div className="">
                                                <strong> Description :</strong>
                                                <span>
                                                    {" "}
                                                    {paymentData?.description}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="text-center mt-3">
                                            Autoriser le paiement
                                        </div>
                                        <div className="border-2 border-black rounded-lg p-5 text-center">
                                            {!token && (
                                                <FormProvider {...form}>
                                                    <form
                                                        onSubmit={form.handleSubmit(
                                                            authentication,
                                                        )}
                                                    >
                                                        <div className="grid grid-cols-12 gap-4">
                                                            <div className="col-span-12">
                                                                <KpInput
                                                                    name="account"
                                                                    placeholder="Numéro de compte Kalypay"
                                                                    type="text"
                                                                    label="Numéro de compte Kalypay"
                                                                ></KpInput>
                                                            </div>
                                                            <div className="col-span-12">
                                                                <KpInput
                                                                    name="password"
                                                                    placeholder="Mot de passe"
                                                                    type="password"
                                                                    label="Mot de passe"
                                                                ></KpInput>
                                                            </div>
                                                            <div className="col-span-12">
                                                                <div className="flex text-center justify-center gap-2">
                                                                    <KpButton
                                                                        type="submit"
                                                                        disabled={
                                                                            processing
                                                                        }
                                                                    >
                                                                        Proceder
                                                                        au
                                                                        paiement
                                                                    </KpButton>
                                                                    <KpButton
                                                                        type="button"
                                                                        mode="reset"
                                                                        disabled={
                                                                            processing
                                                                        }
                                                                        onClick={
                                                                            cancel
                                                                        }
                                                                    >
                                                                        Annuler
                                                                    </KpButton>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </FormProvider>
                                            )}

                                            {token && (
                                                <>
                                                    <div className="text-center mt-3">
                                                        Le solde de votre compte
                                                        est de{" "}
                                                        {UtilsService.formatValue(
                                                            token.wallet.solde,
                                                            token.wallet.type,
                                                        )}{" "}
                                                        {paymentData?.wallet}
                                                    </div>
                                                    <div className="text-center mt-3">
                                                        Votre compte sera débité
                                                        de{" "}
                                                        {UtilsService.formatValue(
                                                            token.amount_to_pay,
                                                            token.wallet.type,
                                                        )}{" "}
                                                        {paymentData?.wallet}
                                                    </div>
                                                    <div className="flex text-center justify-center gap-2 mt-5">
                                                        <KpButton
                                                            type="button"
                                                            onClick={accept}
                                                            disabled={
                                                                processing
                                                            }
                                                        >
                                                            Finaliser le
                                                            paiement
                                                        </KpButton>
                                                        <KpButton
                                                            type="button"
                                                            mode="reset"
                                                            onClick={cancel}
                                                            disabled={
                                                                processing
                                                            }
                                                        >
                                                            Annuler
                                                        </KpButton>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KalypayPayment;
