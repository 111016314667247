import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import User from "../../Models/User";

export interface AuthGlobalState {
    user?: User;
    need2fa: boolean;
    authLoading: boolean;
    isAuthenticated: boolean;
    userLoginLoading: boolean;
    loginError?: string;
    authChecking?: boolean;
}

const initialState: AuthGlobalState = {
    user: undefined,
    need2fa: false,
    authLoading: false,
    isAuthenticated: false,
    userLoginLoading: false,
    loginError: undefined,
    authChecking: false,
};

const Auth = createSlice({
    name: "auth",
    initialState,
    reducers: {
        VerifyTokenStarted(state, action: PayloadAction<{ silent?: boolean }>) {
            if (!action.payload.silent) {
                state.user = undefined;
                state.isAuthenticated = false;
                state.authLoading = false;
                state.loginError = undefined;
            } else {
                state.authLoading = true;
            }
        },

        VerifyTokenEnd(state) {
            state.authLoading = false;
        },

        AuthenticationStarted(state) {
            state.userLoginLoading = true;
            state.loginError = undefined;
        },

        AuthenticationFailure(state, action: PayloadAction<{ error: string }>) {
            state.loginError = action.payload.error;
            state.userLoginLoading = false;
            state.isAuthenticated = false;
        },
        loggedWithSuccess(
            state,
            action: PayloadAction<{
                user: any;
            }>,
        ) {
            const {
                uid,
                reinvest_auto,
                hide_zero_amount,
                hide_not_active_earning,
                last_name,
                first_name,
                username,
                email,
                scopes,
                photo,
                parrain,
                haveTransactionPassword,
                cap,
                cag,
                is_active,
                is_fiat,
                type,
                entreprise,
                siret,
                country,
                date_of_birth,
                city,
                longitude,
                latitude,
                post_code,
                address,
                phone_country_code,
                phone,
                currency,
                active_2fa,
                withdrawal_security_type,
                is_phone_verified,
                last_time_code_sent,
            } = action.payload.user;

            const authenticatedUser = new User(
                uid,
                username,
                last_name,
                first_name,
                email,
                reinvest_auto,
                hide_zero_amount,
                country,
                is_phone_verified,
                last_time_code_sent,
            );

            authenticatedUser.scopes = scopes;
            authenticatedUser.photo = photo;
            authenticatedUser.parrain = parrain;
            authenticatedUser.cag = cag;
            authenticatedUser.cap = cap;
            authenticatedUser.is_active = is_active;
            authenticatedUser.hide_not_active_earning = hide_not_active_earning;
            authenticatedUser.is_fiat = is_fiat;
            authenticatedUser.haveTransactionPassword = haveTransactionPassword;
            authenticatedUser.active_2fa = active_2fa;
            authenticatedUser.type = type;
            authenticatedUser.siret = siret;
            authenticatedUser.entreprise = entreprise;
            authenticatedUser.date_of_birth = date_of_birth;
            authenticatedUser.city = city;
            authenticatedUser.longitude = longitude;
            authenticatedUser.latitude = latitude;
            authenticatedUser.post_code = post_code;
            authenticatedUser.address = address;
            authenticatedUser.phone_country_code = phone_country_code;
            authenticatedUser.phone = phone;
            authenticatedUser.currency = currency;
            authenticatedUser.withdrawal_security_type =
                withdrawal_security_type;

            state.user = authenticatedUser;
            state.isAuthenticated = true;
            state.authLoading = false;
            state.userLoginLoading = false;
            localStorage.setItem("AccountConnected", "yes");
        },
        verifyUserSuccess(state) {
            state.authLoading = false;
            state.userLoginLoading = false;
        },
        Logout(state) {
            state.user = undefined;
            state.isAuthenticated = false;
            state.authLoading = false;
            state.loginError = undefined;
            state.need2fa = false;
            localStorage.setItem("AccountConnected", "false");
        },
        StartAuthChecking(state) {
            state.authChecking = true;
        },
        EndAuthChecking(state) {
            state.authChecking = false;
        },
        ChangeTwoFANeeded(
            state,
            action: PayloadAction<{
                two_fa_needed: boolean;
            }>,
        ) {
            state.need2fa = action.payload.two_fa_needed;
        },
        UpdateUserInformation(
            state,
            action: PayloadAction<{
                user: any;
            }>,
        ) {
            const {
                uid,
                reinvest_auto,
                hide_zero_amount,
                hide_not_active_earning,
                last_name,
                first_name,
                username,
                email,
                scopes,
                photo,
                parrain,
                haveTransactionPassword,
                cap,
                cag,
                is_active,
                is_fiat,
                type,
                entreprise,
                siret,
                country,
                date_of_birth,
                city,
                longitude,
                latitude,
                post_code,
                address,
                phone_country_code,
                phone,
                currency,
                active_2fa,
                withdrawal_security_type,
                is_phone_verified,
                last_time_code_sent,
            } = action.payload.user;

            const authenticatedUser = new User(
                uid,
                username,
                last_name,
                first_name,
                email,
                reinvest_auto,
                hide_zero_amount,
                country,
                is_phone_verified,
                last_time_code_sent,
            );

            authenticatedUser.scopes = scopes;
            authenticatedUser.photo = photo;
            authenticatedUser.parrain = parrain;
            authenticatedUser.cag = cag;
            authenticatedUser.cap = cap;
            authenticatedUser.is_active = is_active;
            authenticatedUser.hide_not_active_earning = hide_not_active_earning;
            authenticatedUser.is_fiat = is_fiat;
            authenticatedUser.haveTransactionPassword = haveTransactionPassword;
            authenticatedUser.active_2fa = active_2fa;
            authenticatedUser.type = type;
            authenticatedUser.siret = siret;
            authenticatedUser.entreprise = entreprise;
            authenticatedUser.date_of_birth = date_of_birth;
            authenticatedUser.city = city;
            authenticatedUser.longitude = longitude;
            authenticatedUser.latitude = latitude;
            authenticatedUser.post_code = post_code;
            authenticatedUser.address = address;
            authenticatedUser.phone_country_code = phone_country_code;
            authenticatedUser.phone = phone;
            authenticatedUser.currency = currency;
            authenticatedUser.withdrawal_security_type =
                withdrawal_security_type;

            state.user = authenticatedUser;
            state.isAuthenticated = true;
            localStorage.setItem("AccountConnected", "yes");
        },
    },
});

export const {
    VerifyTokenStarted,
    VerifyTokenEnd,
    AuthenticationStarted,
    AuthenticationFailure,
    Logout,
    verifyUserSuccess,
    loggedWithSuccess,
    StartAuthChecking,
    EndAuthChecking,
    UpdateUserInformation,
    ChangeTwoFANeeded,
} = Auth.actions;

export default Auth.reducer;
