import { Navigate, Outlet, useLocation } from "react-router-dom";

import useAuthentication from "../../Services/Authentication/useAuthentication";
import RoutesNames from "../../Services/RoutesNames";

interface IProps {
    children?: JSX.Element;
}

const PrivateRouteProvider = ({ children }: IProps) => {
    const { isAuthenticated, user } = useAuthentication();
    const location = useLocation();

    if (isAuthenticated && user?.is_phone_verified) {
        return children ? children : <Outlet />;
    } else {
        return (
            <Navigate
                to={RoutesNames.Login}
                state={{ from: location.pathname }}
                replace
            />
        );
    }
};

export default PrivateRouteProvider;
