import { useEffect, useState, useRef } from "react";

import { NavLink } from "react-router-dom";

import { useAppSelector } from "../../../globalState/hook";
import { CustomMessage } from "../../../Models/Constants";
import alert_message from "../../../Services/Alert/AlertMessage";
import AppAuthentication from "../../../Services/Authentication/AppAuthentication";

import translator from "../../../Components/Extra/Translation/Translate";

import HostInfo from "../../components/HostInfo";
import RoutesNames from "../../../Services/RoutesNames";
import { KpSimpleInput } from "../../../Front/Components/Inputs/KpInput";

import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import { useModalContext } from "../../../Components/Extra/Modal/ModalContext";
import AccountBlocked from "./BlockedAccount";

const AuthComponent = () => {
    const { handleModal } = useModalContext();
    const key =
        process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2 ??
        "6LcE7cIUAAAAAB10AYmbca9JLhs_woOHhugekSS5";

    const { authLoading } = useAppSelector((store) => store.auth);
    const { siginAsync } = AppAuthentication();
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [message, setMessage] = useState<CustomMessage | null>();
    const [token, setToken] = useState<string | null>(null);
    const { translate } = translator();
    const recaptchaRef = useRef<ReCAPTCHA | null>(null);

    const [rememberMe, setRememberMe] = useState(false);
    const [activeTab, setActiveTab] = useState<"email" | "phone">("email");
    const tabs: Array<"email" | "phone"> = ["email", "phone"];

    const isButtonDisabled =
        activeTab === "phone"
            ? password.trim() === "" || phone.length < 10
            : email.length < 5 || password.trim() === "";

    const handleConnexion = async (e: any) => {
        e.preventDefault();
        const userName = activeTab === "phone" ? phone : email;
        if (token) {
            const promise = siginAsync(
                userName,
                password,
                token || "",
                translate,
                rememberMe,
            );
            alert_message
                .promise(`${translate("Authentication", "Sign_in")}`, promise)
                .then(() => {
                    recaptchaRef.current?.reset();
                })
                .catch((err) => {
                    if (typeof err == "string" && err.includes("is blocked")) {
                        handleModal(<AccountBlocked />);
                    }
                    recaptchaRef.current?.reset();
                });
        } else {
            const recaptcha_msg: CustomMessage = {
                type: "error",
                message: "Please verify that you are not a robot.",
            };

            setMessage(recaptcha_msg);
        }
        setToken(null);
    };

    useEffect(() => {
        if (token) setMessage(null);
    }, [token]);

    return (
        <div className="m-auto vl-mob w-full rounded-md sm:block md:flex lg:flex xl:flex 2xl:flex justify-center bg-white-log px-5 py-8 shadow-md sm:w-3/4 sm:px-8 lg:w-2/4 xl:bg-transparent xl:p-0 xl:shadow-none mdg-signup-box">
            <form onSubmit={(e) => handleConnexion(e)}>
                <div className="relative">
                    <HostInfo />
                    <h2 className="intro-x text-center text-2xl font-bold xl:text-left xl:text-3xl mt-8">
                        {translate("SignIn", "Sign_in")}
                    </h2>
                    <div className="intro-x">
                        <div className="relative w-full p-2 rounded-lg dark:bg-slate-700 bg-gray-200 mb-10 mt-9">
                            <div className="relative flex w-full">
                                <div
                                    className="absolute top-0 left-0 w-1/2 h-full bg-kalypay-100 rounded-md transition-transform duration-300 ease-in-out"
                                    style={{
                                        transform: `translateX(${
                                            tabs.indexOf(activeTab) * 100
                                        }%)`,
                                    }}
                                />
                                {tabs.map((tab) => (
                                    <button
                                        key={tab}
                                        onClick={() => setActiveTab(tab)}
                                        className={`px-4 w-full py-2 rounded-md relative z-10 ${
                                            activeTab === tab
                                                ? "text-white"
                                                : "text-gray-700 dark:text-slate-300"
                                        }`}
                                    >
                                        {tab.charAt(0).toUpperCase() +
                                            tab.slice(1)}
                                    </button>
                                ))}
                            </div>
                        </div>
                        {activeTab === "phone" ? (
                            <div className="relative mb-4">
                                <PhoneInput
                                    value={""}
                                    excludeCountries={[
                                        "af",
                                        "ir",
                                        "iq",
                                        "kp",
                                        "ly",
                                        "ss",
                                        "sd",
                                        "sy",
                                        "ye",
                                    ]}
                                    countryCodeEditable={false}
                                    enableLongNumbers={15}
                                    enableSearch={true}
                                    onChange={(phone, data) => {
                                        if (phone) {
                                            const countryCode = (
                                                data as any
                                            ).countryCode.toUpperCase();
                                            const dialCode = (data as any)
                                                .dialCode;
                                            if (dialCode) {
                                                const number = phone
                                                    .replace("+", "")
                                                    .replace(dialCode, "");
                                                setPhone(countryCode + number);
                                            }
                                        }
                                    }}
                                    specialLabel={translate(
                                        "Profil",
                                        "Phone_number",
                                    )}
                                    containerClass="relative z-0 "
                                    inputClass="!h-[45px] !border-slate-200 dark:!border-white/30 !shadow-none"
                                />

                                <label
                                    htmlFor="select"
                                    className="!peer-focus:text-[#053B3B] absolute top-2 left-1  origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                >
                                    {translate("Profil", "Phone_number")}
                                </label>
                            </div>
                        ) : (
                            <div className="relative mt-4">
                                <KpSimpleInput
                                    onChange={(e) => setEmail(e.target.value)}
                                    val={email}
                                    name="username"
                                    type={"text"}
                                    label={translate("SignIn", "ID_OR_EMAIL")}
                                    placeholder={translate(
                                        "SignIn",
                                        "ID_OR_EMAIL",
                                    )}
                                />
                            </div>
                        )}
                        <div className="relative mt-4 -z-10">
                            <KpSimpleInput
                                onChange={(e) => setPassword(e.target.value)}
                                val={password}
                                name="password"
                                type={"password"}
                                label={translate("SignIn", "Password")}
                                placeholder={translate("SignIn", "Password")}
                            />
                        </div>
                    </div>
                    <div className="intro-x mt-4 flex text-xs sm:text-sm">
                        <div className="mr-auto flex items-center">
                            <input
                                id="remember-me"
                                type="checkbox"
                                className="form-check-input mr-2 border"
                                checked={rememberMe}
                                onChange={(e) =>
                                    setRememberMe(e.target.checked)
                                }
                            />
                            <label
                                htmlFor="remember-me"
                                className="cursor-pointer select-none"
                            >
                                {translate("SignIn", "Remember_me")}
                            </label>
                        </div>
                        <NavLink to="/forgot-password" className="md-ml-135">
                            {translate("SignIn", "Forgot_Password")}
                        </NavLink>
                    </div>

                    <div className="intro-x  flex justify-center text-center xl:text-left">
                        <div className="intro-x mt-5 flex justify-center text-center xl:mt-8 xl:text-left">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={key}
                                onChange={(token) => setToken(token)}
                            />
                        </div>
                    </div>
                    <div className="intro-x flex justify-center text-center xl:text-left">
                        {message && !token && (
                            <div
                                className={
                                    message.type === "success"
                                        ? "text-green-600"
                                        : "text-red-500"
                                }
                            >
                                {message.message}
                            </div>
                        )}
                    </div>
                    <div className="intro-x mt-2 xl:flex xl:justify-center lg:flex lg:justify-center text-center xl:text-left block">
                        <button
                            className="btn btn-perfect w-full align-top xl:mr-3 xl:w-32 mb-2"
                            type="submit"
                            disabled={authLoading || isButtonDisabled}
                        >
                            {translate("SignIn", "Sign_in")}
                        </button>
                        <NavLink
                            className="btn btn-outline-secondary w-full align-top xl:mr-3 xl:w-32 mb-2"
                            to={RoutesNames.Register}
                        >
                            {authLoading
                                ? translate("SignIn", "Connecting")
                                : translate("SignIn", "Register")}
                        </NavLink>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default AuthComponent;
