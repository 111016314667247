import Notice, { INotice } from "../../Models/Notice";
import TemplateMail, {
    ITemplateMail,
    IUpdateTemplateMail,
} from "../../Models/TemplateEmail";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

const getById = async (id: number): Promise<TemplateMail | null> => {
    return axios
        .get(`/template-mail/${id}`)
        .then((response) => {
            return response.data;
        })
        .catch((err: any) => {
            console.error(err);
            return null;
        });
};

const remove = async (id: number): Promise<void> => {
    if (!id) {
        throw new Error("L'ID est requis pour supprimer un template.");
    }

    try {
        await axios.delete(`/template-mail/${id}`);
    } catch (error) {
        throw new Error(UtilsService.getAxiosErrorMessage(error));
    }
};

const create = async (
    payload: ITemplateMail,
): Promise<TemplateMail | string> => {
    return axios
        .post("/template-mail", payload, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const update = async (
    templateMailId: number,
    payload: IUpdateTemplateMail,
): Promise<string> => {
    return axios
        .patch(`/template-mail/${templateMailId}`, payload, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(() => {
            return Promise.resolve("Mis à jour avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const findAll = async (): Promise<Array<TemplateMail>> => {
    return axios
        .get(`/template-mail`)
        .then(({ data }) => {
            // const assets: Notice[] = data.map((item: any) => map(item));

            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const sendToUser = async (
    templateMailId: number,
    email: string,
    code: string,
): Promise<string> => {
    return axios
        .post(`/template-mail/${templateMailId}/send-to-user`, {
            email: email,
            code: code,
        })
        .then(() => {
            return Promise.resolve("Envoyé avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const sendToAllUsers = async (
    templateMailId: number,
    code: string,
): Promise<string> => {
    return axios
        .post(`/template-mail/${templateMailId}/send-to-all`, {
            code: code,
        })
        .then(() => {
            return Promise.resolve("Envoyé avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const TemplateEmailService = {
    remove,
    getById,
    create,
    update,
    findAll,
    sendToUser,
    sendToAllUsers,
};

export default TemplateEmailService;
