import React from "react";
import CercleIcon from "./cercle-icon";
import "./loader.css";
import KalypayIcon from "./kalypay-icon";

interface LoaderProps {
    className?: string;
}
export default function Loader({ className }: LoaderProps) {
    return (
        <div className="relative flex justify-center items-center min-h-screen">
            <KalypayIcon className={`absolute scale-[0.55] ${className}`} />
            <CercleIcon className={`rotate-svg ${className}`} />
        </div>
    );
}
