import { useState } from "react";
import PinInput from "react-pin-input";
import translator from "../../../Components/Extra/Translation/Translate";
import alert_message from "../../../Services/Alert/AlertMessage";
import AppAuthentication from "../../../Services/Authentication/AppAuthentication";
import useTheme from "../../../Services/Hooks/useTheme";
import { useAppSelector } from "../../../globalState/hook";
import { AppMode } from "../../../globalState/reducers/Theme.reducer";
import HostInfo from "../../components/HostInfo";

const TwoFALogin = () => {
    const [isChecking, setIsChecking] = useState(false);
    const { translate } = translator();
    const { mode } = useTheme();
    const { verify2faToken, logoutAsync } = AppAuthentication();
    const handleConnexion = (code: string) => {
        setIsChecking(true);
        try {
            const promise = verify2faToken(code);
            alert_message
                .promise("Verify 2fa ...", promise)
                .catch(() => console.log("error"))
                .finally(() => setIsChecking(false));
        } catch (error: any) {}
    };

    const logout = async () => {
        await logoutAsync();
    };

    const inputStyle = {
        borderColor: mode === AppMode.LIGHT ? "#1b253b" : "#595c5c",
        borderRadius: "10px",
    };

    return (
        <form className="m-auto vl-mob w-full rounded-md sm:block md:flex lg:flex xl:flex 2xl:flex justify-center bg-white-log px-5 py-8 shadow-md sm:w-3/4 sm:px-8 lg:w-2/4 xl:bg-transparent xl:p-0 xl:shadow-none">
            <div className="mdg-twofa-cts">
                <HostInfo />
                <h2 className="intro-x text-center text-2xl font-bold xl:text-left xl:text-3xl mt-8">
                    {translate("SignIn", "2FA")}
                </h2>
                <div className="intro-x mt-8">
                    <div className="relative pin">
                        <PinInput
                            length={6}
                            // secret
                            onComplete={(value, index) => {
                                handleConnexion(value);
                            }}
                            focus={true}
                            type="numeric"
                            inputMode="number"
                            style={{ padding: "10px", display: "flex" }}
                            inputStyle={inputStyle}
                            inputFocusStyle={{ borderColor: "#053B3B" }}
                            autoSelect={true}
                            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                            disabled={isChecking}
                        />
                    </div>
                </div>

                <div className="intro-x mt-5 flex justify-center text-center xl:mt-8 xl:text-left gap-3">
                    <button
                        className="btn btn-perfect align-top xl:mr-3 sm:w-32 lg:w-32 md:w-32 xl:w-32 2xl:w-32"
                        type="button"
                        onClick={logout}
                    >
                        {translate("SignIn", "Cancel")}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default TwoFALogin;
