import React from "react";
import comingSoon from "../../../assets/images/coming/coming-soon.png";
import GradientButton from "../../components/button/GradientButton";

const ComingSoon: React.FC = () => {
    return (
        <div>
            <section className="flex justify-center items-center md:pt-32 pt-32 pb-2 md:pb-2 mx-6 lg:mx-32">
                <div className="max-w-screen-xl mx-auto w-full">
                    <div className="flex flex-col md:flex-row justify-center items-center gap-5 sm:gap-4 md:gap-0 text-center md:text-left">
                        <div
                            data-aos="fade-down"
                            className="relative flex justify-center items-center w-full md:w-1/2 sm:mt-5 bg-cover "
                        >
                            <div className="flex justify-center items-center mx-2">
                                <img
                                    src={comingSoon}
                                    alt="comingSoon"
                                    className="w-[83%] animate-moveUpDown duration-[3.5s] ease-in-out infinite opacity-9"
                                />
                            </div>
                        </div>
                        <div
                            data-aos="fade-up"
                            className="flex flex-col justify-start text-left mt-5 items-center md:!items-start w-full md:!w-1/2  align-top md:mt-[-150px]"
                        >
                            <h3 className="font-bold leading-custom text-[23px] md:!text-[35px] font-montserrat text-white">
                                WE ARE
                            </h3>
                            <h2 className="md:!text-5xl text-3xl lg:text-6xl  font-montserrat leading-tight tracking-tighter">
                                COMING SOON
                            </h2>
                            <p className="text-gray-300 my-6 text-base w-[70%] font-light leading-normal font-poppins">
                                Notre équipe travaille dur pour lancer quelque
                                chose d'incroyable. Nous sommes déterminés à
                                vous offrir une expérience unique qui dépassera
                                vos attentes. Merci pour votre patience et votre
                                soutien. Restez à l'écoute pour des mises à jour
                                alors que nous nous rapprochons de notre
                                lancement. Nous avons hâte de partager cela avec
                                vous. Ensemble, nous créons quelque chose de
                                spécial.
                            </p>

                            <GradientButton href="/">
                                Back to Home
                            </GradientButton>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ComingSoon;
