import { useState } from "react";
import { KpSimpleInput } from "../../../Front/Components/Inputs/KpInput";
import PinInput from "react-pin-input";
import TemplateEmailService from "../../../Services/TemplateEmail/TemplateEmailService";
import alert_message from "../../../Services/Alert/AlertMessage";
import SwitchTex from "../../../Components/Extra/Switch/SwitchTex";
import { useModalContext } from "../../../Components/Extra/Modal/ModalContext";

export default function SendMailForm({ id }: { id: number }) {
    const [type, setType] = useState<"user" | "all">("user");
    const [email, setEmail] = useState<string>("");
    const [code, setCode] = useState<string>("");
    const [inProgress, setInProgress] = useState(false);
    const { handleModal } = useModalContext();

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setInProgress(true);
        try {
            if (type === "user") {
                const promise = TemplateEmailService.sendToUser(
                    id,
                    email,
                    code,
                );
                alert_message.promise("Envoi de mail ...", promise);
            } else {
                const promise = TemplateEmailService.sendToAllUsers(id, code);
                alert_message.promise("Envoi de mail ...", promise);
            }
        } catch (error: any) {
            alert_message.error(`Une erreur s'est produite : ${error}`);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="flex justify-end">
                <button
                    type="button"
                    className="text-lg"
                    onClick={() => handleModal()}
                >
                    x
                </button>
            </div>
            <div className="relative">
                <div className="intro-x mt-8 sm:mt-8 flex items-center justify-around">
                    Envoyer à l'email
                    <SwitchTex
                        checked={type === "all"}
                        switch_off=""
                        switch_on=""
                        changeState={() =>
                            setType((t) => (t === "all" ? "user" : "all"))
                        }
                    ></SwitchTex>
                    Envoyer à tout le monde
                </div>
                <div className="intro-x mt-8">
                    {type === "user" && (
                        <div className="relative">
                            <KpSimpleInput
                                onChange={(e) => setEmail(e.target.value)}
                                val={email}
                                name="username"
                                type={"text"}
                                label="Email"
                            />
                        </div>
                    )}

                    <div className="intro-x mt-8">
                        <div className="text-black ">Code 2FA</div>
                        <div className="relative pin">
                            <PinInput
                                length={6}
                                onComplete={(value, index) => {
                                    setCode(value);
                                }}
                                focus={true}
                                type="numeric"
                                inputMode="number"
                                style={{ padding: "10px", display: "flex" }}
                                // inputStyle={inputStyle}
                                inputFocusStyle={{ borderColor: "#053B3B" }}
                                autoSelect={true}
                                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                            />
                        </div>
                    </div>
                    <div className="intro-x mt-2 xl:flex xl:justify-center lg:flex lg:justify-center text-center xl:text-left block">
                        <button
                            className="btn btn-perfect w-full align-top xl:mr-3 xl:w-32 mb-2"
                            type="submit"
                            disabled={
                                inProgress ||
                                code.length < 6 ||
                                (type === "user" && email.length < 3)
                            }
                        >
                            Envoyer
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
}
