import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SaveBouton from "../../../Components/Extra/Bouton/SaveBouton";
import alert_message from "../../../Services/Alert/AlertMessage";
import translator from "../../../Components/Extra/Translation/Translate";
import { LangType } from "../../../Models/Lang";
import useLanguage from "../../../Services/Hooks/useLanguage";
import WysiwigInput from "../../../Components/Extra/WYSIWYG/WysiwigInput";
import { ITemplateMail } from "../../../Models/TemplateEmail";
import TemplateEmailService from "../../../Services/TemplateEmail/TemplateEmailService";
import { Language } from "../../../globalState/reducers/Language.reducer";

interface TemplateMailFormProps {
    id?: number;
}

const TemplateMailForm = ({ id }: TemplateMailFormProps) => {
    const { translate } = translator();
    const { language } = useLanguage();
    const navigate = useNavigate();
    const location = useLocation();
    const { asset } = location.state || {};
    const [templateName, setTemplateName] = useState<string>(
        asset?.templateName || "",
    );
    const [active, setActive] = useState<Language>(Language.FR);
    const [templateContent, setTemplateContent] = useState<{
        [key in Language]: { object: string; content: string };
    }>({
        [Language.FR]: {
            object: asset?.templateContent[Language.FR]?.object || "",
            content: asset?.templateContent[Language.FR]?.content || "",
        },
        [Language.EN]: {
            object: asset?.templateContent[Language.EN]?.object || "",
            content: asset?.templateContent[Language.EN]?.content || "",
        },
        [Language.ES]: {
            object: asset?.templateContent[Language.ES]?.object || "",
            content: asset?.templateContent[Language.ES]?.content || "",
        },
        [Language.DE]: {
            object: asset?.templateContent[Language.DE]?.object || "",
            content: asset?.templateContent[Language.DE]?.content || "",
        },
        [Language.PT_BRA]: {
            object: asset?.templateContent[Language.PT_BRA]?.object || "",
            content: asset?.templateContent[Language.PT_BRA]?.content || "",
        },
        [Language.TR]: {
            object: asset?.templateContent[Language.TR]?.object || "",
            content: asset?.templateContent[Language.TR]?.content || "",
        },
        [Language.ZH]: {
            object: asset?.templateContent[Language.ZH]?.object || "",
            content: asset?.templateContent[Language.ZH]?.content || "",
        },
    });

    const createTemplateMail = (templateMail: ITemplateMail) => {
        TemplateEmailService.create(templateMail)
            .then(() => {
                alert_message.success(
                    translate("Admin_TemplateMail", "Creation_Success"),
                );
                navigate("/admin/template-email");
            })
            .catch((err) => {
                alert_message.error(err);
            });
    };

    const updateTemplateMail = (id: number, templateMail: ITemplateMail) => {
        TemplateEmailService.update(id, templateMail)
            .then(() => {
                alert_message.success(
                    translate("Admin_TemplateMail", "Update_Success"),
                );
                navigate("/admin/template-email");
            })
            .catch((err) => {
                alert_message.error(err);
            });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        const templateMail: ITemplateMail = {
            templateName: templateName,
            templateContent: templateContent,
        };

        if (id) {
            updateTemplateMail(id, templateMail);
        } else {
            createTemplateMail(templateMail);
        }
    };

    const getTemplateMail = async () => {
        if (id && !asset) {
            try {
                const res = await TemplateEmailService.getById(Number(id));

                if (!res) {
                    throw new Error("Template not found");
                }

                setTemplateName(res.templateName);
                setTemplateContent({
                    [Language.FR]: {
                        object:
                            res.templateContent?.[LangType.FR]?.object || "",
                        content:
                            res.templateContent?.[LangType.FR]?.content || "",
                    },
                    [Language.EN]: {
                        object:
                            res.templateContent?.[LangType.EN]?.object || "",
                        content:
                            res.templateContent?.[LangType.EN]?.content || "",
                    },
                    [Language.ES]: {
                        object:
                            res.templateContent?.[Language.ES]?.object || "",
                        content:
                            res.templateContent?.[Language.ES]?.content || "",
                    },
                    [Language.DE]: {
                        object:
                            res.templateContent?.[Language.DE]?.object || "",
                        content:
                            res.templateContent?.[Language.DE]?.content || "",
                    },
                    [Language.PT_BRA]: {
                        object:
                            res.templateContent?.[Language.PT_BRA]?.object ||
                            "",
                        content:
                            res.templateContent?.[Language.PT_BRA]?.content ||
                            "",
                    },
                    [Language.TR]: {
                        object:
                            res.templateContent?.[Language.TR]?.object || "",
                        content:
                            res.templateContent?.[Language.TR]?.content || "",
                    },
                    [Language.ZH]: {
                        object:
                            res.templateContent?.[Language.ZH]?.object || "",
                        content:
                            res.templateContent?.[Language.ZH]?.content || "",
                    },
                });
                setActive(Language.FR);
            } catch (err: any) {
                alert_message.error(err.message || "An error occurred");
                setTimeout(() => navigate("/admin/template-mail/"), 3000);
            }
        }
    };

    const toggleActive = (value: Language) => {
        setActive(value);
    };

    useEffect(() => {
        getTemplateMail();
    }, [id]);

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 p-4">
                    <div className="grid gap-6 box">
                        <div className="col-span-12 mt-8 sm:col-span-12 lg:col-span-9 2xl:col-span-9 mx-4 ">
                            <div className="relative z-0 w-full group mb-4">
                                <input
                                    type="text"
                                    id="template-name"
                                    className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                    placeholder=" "
                                    value={templateName}
                                    onChange={(e) =>
                                        setTemplateName(e.target.value)
                                    }
                                    required
                                />
                                <label
                                    htmlFor="template-name"
                                    className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                >
                                    Nom du template
                                </label>
                            </div>
                            <ul className="flex flex-wrap ul-bg-mode text-sm font-medium sm-w-inherit md:w-full lg:w-full xl:w-full bg-white text-center text-gray-500 border-gray-200 rounded-t-lg mb-4">
                                {Object.values(Language).map((lang: string) => {
                                    return (
                                        <li
                                            key={lang}
                                            className={
                                                active === lang
                                                    ? "inline-block py-[16px] sm:px-2 md:px-4 lg:px-4 xl:px-4 2xl:px-4 cursor-pointer rounded-t-lg !border-b-[3px] !border-[#daa520] pb-[13px]"
                                                    : "inline-block py-[16px] sm:px-2 md:px-4 lg:px-4 xl:px-4 2xl:px-4 cursor-pointer rounded-t-lg nav-not-active"
                                            }
                                            onClick={() =>
                                                toggleActive(lang as Language)
                                            }
                                        >
                                            {lang.toUpperCase()}
                                        </li>
                                    );
                                })}
                            </ul>
                            <div className="grid md:grid-cols-3 md:gap-6">
                                <div className="relative z-0 w-full group">
                                    <input
                                        type="text"
                                        id="object"
                                        className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                        placeholder=" "
                                        value={templateContent[active]?.object}
                                        onChange={(e) => {
                                            const updatedContent = {
                                                ...templateContent,
                                                [active]: {
                                                    ...templateContent[active],
                                                    object: e.target.value,
                                                },
                                            };
                                            setTemplateContent(updatedContent);
                                        }}
                                        required
                                    />
                                    <label
                                        htmlFor="object"
                                        className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                    >
                                        {translate(
                                            "Admin_TemplateMail",
                                            "Object",
                                        )}
                                    </label>
                                </div>
                            </div>
                            <div className="relative z-0 w-full group mt-6">
                                <div className="editor-container">
                                    <WysiwigInput
                                        key={active} // Force la réinitialisation du composant
                                        content={
                                            templateContent[active]?.content
                                        }
                                        setContent={(value) => {
                                            const updatedContent = {
                                                ...templateContent,
                                                [active]: {
                                                    ...templateContent[active],
                                                    content: value,
                                                },
                                            };
                                            setTemplateContent(updatedContent);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 text-right">
                        <SaveBouton htmlType="submit">
                            {id
                                ? translate("Admin_Notice", "Edit")
                                : translate("Admin_Notice", "Save")}{" "}
                        </SaveBouton>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default TemplateMailForm;
