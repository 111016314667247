import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useLanguage from "../../../Services/Hooks/useLanguage";
import { allFiles } from "../../components/mdx/MdxFileLoader";
import { SupportPost } from "../../components/mdx/mdx-helpers";
import KalyssiFooter from "../../layout/Kalyssi/KalyssiFooter";
import SupportHeader from "./header";
import SupportSidebar from "./sidebar";
import SinglePost from "./single-post";
import Header from "./headerMain";

export default function SupportLayout() {
    const { group, item } = useParams();

    const [post, setPost] = useState<SupportPost | undefined | null>();
    const { language } = useLanguage();

    const lang = language.toLocaleLowerCase();

    const getPost = async (slug: string) => {
        const files = await allFiles();

        const find_post = files.find(
            (it) => it.data.topic.slug === slug && it.data.topic.lang === lang,
        );

        console.log(find_post);
        if (find_post) setPost(find_post);
    };
    const location = useLocation();

    useEffect(() => {
        const html = document.querySelector("html");
        if (html) {
            html.style.scrollBehavior = "auto";
            window.scroll({ top: 0 });
            html.style.scrollBehavior = "";
        }
    }, [location.pathname]);

    useEffect(() => {
        getPost(item || "");
    }, [item, lang]);
    return (
        <>
            <Header />
            <SupportHeader />

            {/* Page content */}
            <main className="grow overflow-x-hidden no-scrollbar bg-white">
                <section className="relative z-10  min-h-[66vh] bg-white ">
                    <div className="max-w-6xl mx-auto px-4 sm:px-6 py-10">
                        {/* Main content */}
                        <div className="md:flex md:justify-between">
                            <SupportSidebar />
                            {post && <SinglePost post={post}></SinglePost>}
                        </div>
                    </div>
                </section>
            </main>
            <div className="overflow-x-hidden md:overflow-visible">
                <KalyssiFooter />
            </div>
        </>
    );
}
