import React, { useCallback, useEffect, useState } from "react";
import update from "immutability-helper";
import Card from "./Card";
import translator from "../../../../Components/Extra/Translation/Translate";
import useLanguage from "../../../../Services/Hooks/useLanguage";
import { LangType } from "../../../../Models/Lang";
import TemplateMail from "../../../../Models/TemplateEmail";
import TemplateEmailService from "../../../../Services/TemplateEmail/TemplateEmailService";
import { ModalProvider } from "../../../../Components/Extra/Modal/ModalContext";

const Container: React.FC = () => {
    const { translate } = translator();
    const { language } = useLanguage();
    const lang = language.toLowerCase() === "fr" ? LangType.FR : LangType.EN;
    const [cards, setCards] = useState<TemplateMail[]>([]);

    const getAll = async () => {
        try {
            const res = await TemplateEmailService.findAll();
            setCards(res);
        } catch (err: any) {
            console.error("Error fetching templates:", err);
        }
    };

    useEffect(() => {
        getAll();
    }, []);

    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setCards((prevCards) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex]],
                ],
            }),
        );
    }, []);

    return (
        <ModalProvider>
            <div className="container mx-auto">
                {cards.map((card, i) => (
                    <Card
                        key={card.id}
                        index={i}
                        id={card.id}
                        template={card}
                        moveCard={moveCard}
                        refreshList={getAll}
                        lang={lang}
                    />
                ))}
            </div>
        </ModalProvider>
    );
};

export default Container;
