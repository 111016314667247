import ComingSoon from "./section1";
import Section2 from "./section2";

const Coming: React.FC = () => {
    return (
        <div>
            <ComingSoon />
            {/* <Section2 /> */}
        </div>
    );
};

export default Coming;
