import Currency, { ICurrency } from "../../Models/Currency";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

export type CurrencySymbol = {
    abbrev: string;
    symbol: string;
};

const CurrencyMap = (item: any): Currency => {
    const currency = new Currency(
        item.name,
        item.symbol,
        item.abbrev,
        item.usd_unit_price,
    );
    currency.created_at = item.created_at;
    currency.updated_at = item.updated_at;
    currency.id = item.id;

    currency.countries = item.countries ?? [];

    return currency;
};

const getAll = async (): Promise<Array<Currency>> => {
    return axios
        .get(`/currency`)
        .then(({ data }) => {
            const currencies: Currency[] = data.map((item: any) =>
                CurrencyMap(item),
            );

            return currencies;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const deleteById = async (currency_id?: number) => {
    return axios
        .delete(`/currency/${currency_id}`)
        .then((res) => Promise.resolve(res.data.message))
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const create = async (payload: ICurrency): Promise<Currency | string> => {
    return axios
        .post("/currency", {
            ...payload,
        })
        .then(() => {
            return Promise.resolve("Currency créé avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const update = async (
    currency_id: number,
    payload: ICurrency,
): Promise<string> => {
    return axios
        .put(`/currency/${currency_id}`, {
            ...payload,
        })
        .then(() => {
            return Promise.resolve("Mis à jour avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllAbbreviations = async (): Promise<Array<any>> => {
    return axios
        .get(`/currency/abbrev`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getByAbbreviation = async (abbr: string): Promise<string> => {
    return axios
        .get(`/currency/get-by-abbr/${abbr}`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllAbbreviationsAndSymbols = async (): Promise<
    Array<CurrencySymbol>
> => {
    return axios
        .get(`/currency/abbreviations-symbols`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const CurrencyService = {
    getAll,
    deleteById,
    create,
    update,
    getAllAbbreviations,
    getByAbbreviation,
    getAllAbbreviationsAndSymbols,
};

export default CurrencyService;
