import MerchantCategory, {
    IMerchantCategory,
} from "../../../Models/MerchantCategory";
import { IFilterByCategoryAndCountry } from "../../../Models/Payment";
import { MerchantPublic } from "../../../Models/Roles/Merchant";
import axios from "../../AxiosInstance";
import UtilsService from "../../UtilsService";

const mapMerchantCategory = (item: any): MerchantCategory => {
    const category = new MerchantCategory(item.name);

    category.created_at = item.created_at;
    category.updated_at = item.updated_at;
    category.id = item.id;

    return category;
};

const getAll = async (): Promise<Array<MerchantCategory>> => {
    return axios
        .get(`/merchant-category`)
        .then(({ data }) => {
            const res: MerchantCategory[] = data.map((item: any) =>
                mapMerchantCategory(item),
            );

            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getById = async (id: number): Promise<MerchantCategory> => {
    return axios
        .get(`/merchant-category/${id}`)
        .then(({ data }) => {
            const merchant: MerchantCategory = mapMerchantCategory(data);

            return merchant;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const create = async (payload: IMerchantCategory) => {
    try {
        const result = await axios.post(`/merchant-category`, {
            ...payload,
        });

        return Promise.resolve(result.data.message);
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(error);
    }
};

const findAllByCountryAndCategory = async (
    payload: IFilterByCategoryAndCountry,
): Promise<MerchantPublic[]> => {
    return axios
        .post(`/merchant/get-by-country-category`, {
            ...payload,
        })
        .then(({ data }) => {
            return data as MerchantPublic[];
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const update = async (payload: IMerchantCategory, id: number) => {
    try {
        const result = await axios.put(`/merchant-category/${id}`, {
            ...payload,
        });

        return Promise.resolve(result.data.message);
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(error);
    }
};

const deleteById = async (id?: number) => {
    return axios
        .delete(`/merchant-category/${id}`)
        .then((res) => {
            return Promise.resolve(res.data.message);
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const MerchantCategoryService = {
    getAll,
    getById,
    create,
    update,
    deleteById,
    findAllByCountryAndCategory,
};

export default MerchantCategoryService;
