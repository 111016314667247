import React, { useState, useEffect } from "react";
import { CornerRightUp } from "lucide-react";

const BackToTopButton: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.scrollY > 2000) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <button
            onClick={scrollToTop}
            className={`
        fixed right-0 p-4 bg-slate-600 z-40 rounded-l-full cursor-pointer
        transition-opacity duration-300 ease-in-out
        ${isVisible ? "opacity-100" : "opacity-0 pointer-events-none"}
        transform -translate-y-1/2 top-1/2
      `}
        >
            <CornerRightUp
                className="text-kalypay-200 hover:text-yellow-500"
                size={25}
            />
        </button>
    );
};

export default BackToTopButton;
